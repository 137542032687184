<template>
  <div class="violations-modal__event">
    <r-title type="subtitle-2">
      Данное нарушение имеет связь с событием из ОДД
    </r-title>
    <div
      v-if="event"
      class="violations-modal__event-info"
    >
      <r-title type="subtitle-1">
        {{ eventAddress }}
      </r-title>
      <r-text>
        {{ $ritmDate.toFormat(event.updated_at, 'DD.MM.YYYY • HH:mm') }}
      </r-text>
      <a
        :href="`/app/odd/objects?id=${source.event_id}`"
        rel="noopener noreferrer"
        target="_blank"
      >
        <r-text color-type="accent-primary">
          {{ eventName }}
        </r-text>
      </a>
    </div>
    <div v-else>
      <r-text color-type="secondary">
        Событие удалено или завершено
      </r-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      event: ''
    }
  },
  computed: {
    eventName() {
      return this.event?.name
    },
    eventAddress() {
      return this.event?.address
    }
  },
  mounted() {
    this.event =
      this.source.related.event.data.find(e => e.id === this.source.event_id) ||
      null
  }
}
</script>

<style lang="scss">
.violations-modal__event {
  display: grid;
  grid-gap: 0.5rem;
  background-color: var(--bg_containers);
  border-radius: var(--border-radius);
  padding: 0.5rem;

  &-info {
    display: grid;
    grid-auto-flow: column;
    justify-content: start;
    grid-gap: 1rem;
    align-items: center;
    height: 36px;
    background-color: var(--bg_containers);
    border-radius: var(--border-radius);
    padding: 0 0.5rem;
  }
}
</style>
